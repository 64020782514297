<template>
  <v-container fluid>
    <v-row no-gutters class="d-flex justify-space-between mt-5 mb-1">
      <h1 class="page-title">Review Fraud</h1>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <h7>No. LHI</h7>
        <v-text-field v-model="noLhi" class="mb-2 white mr-1" hide-details="auto" dense placeholder="Cari no. LHI"
          label="" outlined></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <h7>Lokasi Kejadian</h7>
        <v-text-field v-model="lokasi" class="mb-2 white mr-1" hide-details="auto" dense
          placeholder="Cari Lokasi Kejadian" label="" outlined></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <h7>Tanggal Selesai Investigasi</h7>
        <v-menu ref="menu1" v-model="menuForm" :close-on-content-click="false" transition="scale-transition"
          min-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="reportDateForm" label="" prepend-inner-icon="mdi-calendar" v-bind="attrs" v-on="on"
              readonly outlined dense hide-details="auto" class="mb-0 white mr-1" placeholder=""
              clearable></v-text-field>
          </template>
          <v-date-picker v-model="reportDateFromPicker" no-title scrollable @input="pickDateForm"></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <h7>Jenis Fraud</h7>
        <v-select v-model="typeStatus" class="mb-0 white mr-1" hide-details="auto" dense placeholder="Semua"
          :items="jenisStatus" item-text="paramName" item-value="paramCd" outlined></v-select>
      </v-col>
      <v-col cols="12" md="4">
        <h7>Status</h7>
        <v-select v-model="infoStat" class="mb-0 white mr-1" hide-details="auto" dense placeholder="Semua"
          :items="infoStatus" item-text="paramName" item-value="paramCd" outlined></v-select>
      </v-col>
      <v-col cols="12" md="4">
        <h7 class="label-text-field white--text" color="primary">""</h7>
        <v-row cols="12" md="4" class="text--right">
          <v-btn @click="handleGetReview" class="evelation-0 text-capitalize ml-3" cols="1" color="primary">
            <span class="white--text">Cari</span>
          </v-btn>
          <v-btn @click="reset" depressed outlined color="primary" cols="1"
            class="elevation-0 text-capitalize ml-1 primary--text">
            <span class="orange--text">Reset</span>
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mb">
      <v-col>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-data-table class="tableData" :headers="headersReviewFraud" :items="allInquiryReviewFraud"
                  :items-per-page="5" :header-props="{ sortIcon: null }" disable-sort mobile-breakpoint="0">
                  <template v-slot:item="data">
                    <tr class="trReview">
                      <td class="tdReview orange--text">
                        {{ data.item.jagaNo }}
                      </td>
                      <td class="tdReview">
                        {{ data.item.investigationEndDateStr }}
                      </td>
                      <td class="tdReview">{{ data.item.fraudLocName }}</td>
                      <td class="tdReview">{{ data.item.fraudTypeName }}</td>
                      <td class="tdReview">
                        <v-chip class="ma-2" color="orange lighten-4" text-color="orange">
                          {{ data.item.reportStatusName }}
                        </v-chip>
                      </td>
                      <td>
                        <span :class="`${data.item.slaColor.toLowerCase()}--text`">
                          {{ data.item.sla }}
                        </span>
                      </td>
                      <td align="center" class="tdReview">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn small text class="text-capitalize black--text px-0" v-on="on" v-bind="attrs"
                              @click="handleDownloadDataLhi(data.item.fraudId)">
                              <v-icon aria-hidden="false" style="font-size: 20px"
                                color="#F5821F">mdi-printer-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>Print</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn small text class="text-capitalize black--text px-0" v-on="on" v-bind="attrs"
                              :to="`/review-fraud/${data.item.fraudId}/form`">
                              <v-icon aria-hidden="false" style="font-size: 20px"
                                color="#F5821F">mdi-share-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>Process</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
import moment from "moment";
var dateFormat = require("dateformat");

export default {
  data() {
    return {
      headersReviewFraud: [
        {
          text: "No. LHI",
          align: "center",
          width: "150px",
        },
        {
          text: "Tanggal Selesai Investigasi",
          align: "center",
          width: "180px",
        },
        {
          text: "Lokasi Kejadian",
          align: "center",
          width: "158px",
        },
        {
          text: "Jenis Fraud",
          align: "center",
          width: "134px",
        },
        {
          text: " Status",
          align: "center",
          width: "170px",
        },
        {
          text: "Running SLA",
          align: "center",
          width: "200px",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "200px",
        },
      ],
      currenDate: dateFormat(
        new Date().toISOString().substr(0, 10),
        "yyyy-mm-dd"
      ),
      infoStatus: [],
      jenisStatus: [],
      menuForm: false,
      menuTo: false,
      reportDateFromPicker: null,
      reportDateToPicker: null,
      reportDateForm: "",
      reportDateTo: "",
      noLhi: "",
      lokasi: "",
      typeStatus: "",
      infoStat: "",
      inquiryReviewFraud: [],
    };
  },

  mounted() {
    this.loadData();
    this.loadJenisFraud();
    this.loadInfoStatus();
  },

  created() {
    this.requiredFeature('REVIEW_FRAUD')
    this.changeTitleApp("Review Fraud");
  },

  methods: {
    ...mapActions([
      "searchReviewFraud",
      "changeTitleApp",
      "getParameterByParamType",
      "downloadDataLhi",
    ]),

    handleDownloadDataLhi(fraudId) {
      this.downloadDataLhi(fraudId)
        .then((resp) => {
          console.log("susccess", resp);
        })
        .catch((err) => {
          this.$helpers.handleError(err);
          console.log("error", err.response);
        });
    },

    reset: function () {
      this.noLhi = "";
      this.lokasi = "";
      this.reportDateForm = "";
      this.typeStatus = "";
      this.infoStat = "";
      this.loadData();
    },

    loadData() {
      this.searchReviewFraud({
        jagaNo: this.noLhi,
        fraudLoc: this.lokasi,
        fraudType: this.typeStatus,
        pageNumber: 0,
        pageSize: 1000000,
        reportDate: "",
        investigationEndDt: this.reportDateForm
          ? moment(this.reportDateForm, "DD-MMM-YYYY").format("yyyy-MM-DD")
          : "",
        // reportDateForm: this.reportDateForm,
        reportDateTo: this.reportDateTo,
        reportStatus: this.infoStat,
      })
        .then((resp) => {
          console.log("ini memunculkan semua data", resp);
        })
        .catch((err) => {
          this.$helpers.handleError(err);
          console.log("error data tidak muncul", err.response);
        });
    },

    loadJenisFraud() {
      this.getParameterByParamType({
        paramType: "FRAUD_TYPE",
      })
        .then((resp) => {
          this.jenisStatus = resp.data.data.filter(
            (item) =>
              item.paramCd == "FRAUD_TYPE_EKSTERNAL" ||
              item.paramCd == "FRAUD_TYPE_INTERNAL"
          );

          console.log("ini jenis fraud", this.jenisStatus);
        })
        .catch((err) => {
          this.$helpers.handleError(err.response);
        });
    },

    loadInfoStatus() {
      this.getParameterByParamType({
        paramType: "REPORT_STATUS",
      })
        .then((resp) => {
          this.infoStatus = resp.data.data.filter(
            (item) => item.paramCd == "REPORT_STATUS_DELEGATE_REVIEWER" ||
              item.paramCd == "REPORT_STATUS_INVESTIGATION_RE_SUBMIT"
          );
          console.log("ini info status", this.infoStatus);
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },

    handleGetReview() {
      this.searchReviewFraud({
        jagaNo: this.noLhi,
        fraudLoc: this.lokasi,
        fraudType: this.typeStatus,
        pageNumber: 0,
        pageSize: 1000000,
        reportDate: "",
        investigationEndDt: this.reportDateForm
          ? moment(this.reportDateForm, "DD-MMM-YYYY").format("yyyy-MM-DD")
          : "",
        // reportDateForm: this.reportDateForm,
        reportDateTo: this.reportDateTo,
        reportStatus: this.infoStat,
      })
        .then((resp) => {
          this.inquiryReviewFraud = resp.data.data;
          console.log(
            "ini untuk memunculkan data yang akan di pilih ",
            resp.data.data
          );
        })
        .catch(() => {
          this.inquiryReviewFraud = [];
        });
    },

    pickDateForm() {
      this.menuForm = false;
      this.reportDateForm = moment(this.reportDateFromPicker).format(
        "DD-MMM-YYYY"
      );
    },

    pickDateTo() {
      this.menuTo = false;
      this.reportDateTo = moment(this.reportDateToPicker).format("yyyy-MM-DD");
    },
  },

  computed: {
    allInquiryReviewFraud() {
      return Object.values(this.$store.getters.allInquiryReviewFraud);
    },
  },
};
</script>


<style src="./ReviewFraud.scss" lang="scss" />


